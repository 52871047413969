import Pic1 from './1a.jpeg';
import Pic2 from './2a.jpeg';
import Pic3 from './3a.jpeg';
import Pic4 from './4a.jpeg';
import Pic5 from './5a.jpeg';
import Pic6 from './6a.jpeg';
import Pic7 from './7a.jpeg';
import Pic8 from './8a.jpeg';
import Pic9 from './9a.jpeg';
import Pic10 from './1b.jpeg';
import Pic11 from './2b.jpeg';
import Pic13 from './4b.jpeg';
import Pic14 from './5b.jpeg';


export const picArray = [
    Pic1,
    Pic2,
    Pic3,
    Pic4,
    Pic5,
    Pic6,
    Pic7,
    Pic8,
    Pic9,
    Pic10,
    Pic11,
    Pic13,
    Pic14
]
